document.addEventListener("DOMContentLoaded", function() {
  const tooltipElements = document.querySelectorAll('.tooltip');

  function applyClasses() {

    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    tooltipElements.forEach((element, index) => {
      if (isMobile) {
        const position = (index % 6) + 1;
        if (position === 1 || position === 2) {
          element.classList.add('!translate-x-0', '!left-0');
        }

        if (position === 5 || position === 6) {
          element.classList.add('!translate-x-0', '!right-0', '!left-auto');
        }
      } else {
        const position = (index % 16) + 1;
        if (position === 1 || position === 2) {
          element.classList.add('!translate-x-0', '!left-0');
        }

        if (position === 15 || position === 16) {
          element.classList.add('!translate-x-0', '!right-0', '!left-auto');
        }
      }
    });
  }

  applyClasses();
  window.addEventListener('resize', applyClasses);
});
