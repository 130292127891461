import {
	formatValue,
	clearView,
	createCategoryButton,
	createCategoryDiv,
	hideUnselectedCategories,
	showSelectedCategory,
	createCategoryOptions,
	updateInitialButtonWithSelection,
	createCustomField,
	createCategoryBack,
	createOverlayTrigger,
	setActiveView,
	hideView,
	updateCounter,
	toggleSelected,
	// ... your other imports
  } from "./utils";
  
  import {
	VIEW_2,
	VIEW_3,
  
	INITIAL_BUTTONS,
	NATIVE_SELECTS,
	SELECT_DATA,
	RESET_BUTTONS,
	COUNTER,
  
	SELECTED_ICON_TOP,
	SELECTED_ICON_LEFT,
	SELECTED_ICON_BOTTOM,
  
	SUBMITTED_ICON_TOP,
	SUBMITTED_ICON_LEFT,
	SUBMITTED_ICON_BOTTOM,
  
	SUBMITTED_LABEL_TOP,
	SUBMITTED_LABEL_LEFT,
	SUBMITTED_LABEL_BOTTOM,
  
	FORM_SUCCESS,
  } from "./dom";
  
  // Global array from Twig
  const TOP_LEVEL_ICONS = window.CATEGORY_ICONS || [];
  
  // For tracking the user’s selection logic
  const CUSTOM_SELECTS = [];
  const CUSTOM_INPUT_FIELDS = [];
  const SELECTED_CUSTOM_OPTIONS = new Set();
  
  // Which of the 3 big circle buttons is currently active
  let selectIndex = 0;
  
  // Attempt to load from localStorage
  const FORM_STATE = JSON.parse(window.localStorage.getItem("formState")) || null;
  
  /**
   * Each index = 0->left, 1->top, 2->bottom
   * .text will store either child label (if user picks child)
   * or the parent's label (if user uses custom input)
   */
  let nativeOptionsState = [
	{
	  selected: false,
	  value: "",
	  text: "",
	  imageUrl: "",
	  customUsed: false,
	  parentLabel: "",
	},
	{
	  selected: false,
	  value: "",
	  text: "",
	  imageUrl: "",
	  customUsed: false,
	  parentLabel: "",
	},
	{
	  selected: false,
	  value: "",
	  text: "",
	  imageUrl: "",
	  customUsed: false,
	  parentLabel: "",
	},
  ];
  
  // Query hidden inputs (if present)
  const iconLeftHidden = document.querySelector(".iconLeftUrl");
  const iconTopHidden = document.querySelector(".iconTopUrl");
  const iconBottomHidden = document.querySelector(".iconBottomUrl");
  
  // Sync hidden inputs with nativeOptionsState
  function updateHiddenInputs() {
	if (iconLeftHidden) {
	  iconLeftHidden.value = nativeOptionsState[0].imageUrl || "";
	}
	if (iconTopHidden) {
	  iconTopHidden.value = nativeOptionsState[1].imageUrl || "";
	}
	if (iconBottomHidden) {
	  iconBottomHidden.value = nativeOptionsState[2].imageUrl || "";
	}
  }
  
  /* --------------------------
	 RESTORE / INITIAL SETUP
  -------------------------- */
  export function normalizeParentTitle(str) {
	if (!str) return "";
	let output = str.toLowerCase();
	output = output
	  .replace(/æ/g, "ae")
	  .replace(/ø/g, "o")
	  .replace(/å/g, "a")
	  // Remove all spaces:
	  .replace(/\s+/g, "");
	return output;
  }
  
  const setSelectedIcons = () => {
	if (!FORM_STATE) return;
  
	const iconArrays = [SELECTED_ICON_LEFT, SELECTED_ICON_TOP, SELECTED_ICON_BOTTOM];
	
	iconArrays.forEach((iconList, idx) => {
	  if (!iconList || !iconList.length) return;
  
	  iconList.forEach((imgEl) => {
		imgEl.src = FORM_STATE[idx].imageUrl;
	  });
	});
  };  
  
  const setSelectedOptions = () => {
	FORM_STATE.forEach((formGroup, index) => {
	  const selectElement = NATIVE_SELECTS[index];
	  if (!selectElement) {
		console.warn(`No select element found for form group at index ${index}.`);
		return;
	  }
  
	  // Clear old selection
	  const options = [...selectElement.querySelectorAll("option")];
	  options.forEach((option) => (option.selected = false));
  
	  // Try real <option> match
	  const matchingOption = options.find(
		(option) => formatValue(formGroup.value) === formatValue(option.value)
	  );
  
	  if (matchingOption) {
		matchingOption.selected = true;
		if (formGroup.text) {
		  updateInitialButtonWithSelection({
			buttons: INITIAL_BUTTONS,
			selectIndex: index,
			selectionText: formGroup.text,
			imageUrl: formGroup.imageUrl,
		  });
		}
	  } else if (formGroup.selected && formGroup.value) {
		// custom input scenario
		console.log(`Custom input restored at index ${index}:`, formGroup.value);
		updateInitialButtonWithSelection({
		  buttons: INITIAL_BUTTONS,
		  selectIndex: index,
		  selectionText: formGroup.text,
		  imageUrl: formGroup.imageUrl,
		});
	  }
	});
  };
  
  const setSubmittedIcons = () => {
	[SUBMITTED_ICON_LEFT, SUBMITTED_ICON_TOP, SUBMITTED_ICON_BOTTOM].forEach((span, idx) => {
	  const state = nativeOptionsState[idx];
	  if (!span) return;
  
	  if (state.customUsed) {
		// typed input -> show parent's label (normalized)
		span.textContent = normalizeParentTitle(state.parentLabel);
	  } else {
		// child pick -> show child's .value
		span.textContent = state.value
			.replace(/æ/g, "ae")
			.replace(/ø/g, "o")
			.replace(/å/g, "a")
			.replace(" ", "")
			.toLowerCase();
	  }
	});
  };
  
  const setSubmittedLabels = () => {
	const labelArrays = [SUBMITTED_LABEL_LEFT, SUBMITTED_LABEL_TOP, SUBMITTED_LABEL_BOTTOM];
  
	labelArrays.forEach((nodeList, idx) => {
	  if (!nodeList || !nodeList.length) return;
  
	  const { text } = nativeOptionsState[idx] || {};
	  const labelText = (text || "").toLowerCase();
  
	  nodeList.forEach((lblElem) => {
		lblElem.textContent = labelText;
	  });
	});
  };

  if (FORM_STATE) {
	nativeOptionsState = FORM_STATE;
  
	if (FORM_SUCCESS) {
	  setSubmittedIcons();
	  setSubmittedLabels();
	}
	if (COUNTER) {
	  updateCounter({ nativeOptionsState, counter: COUNTER });
	  setSelectedOptions();
	}
	if (SELECTED_ICON_TOP) {
	  setSelectedIcons();
	}
	updateHiddenInputs();
  }
  
  /* ---------------------------
	 LOCAL STORAGE UPDATERS
  --------------------------- */
  const updateLocalStorageState = (state) => {
	window.localStorage.setItem("formState", JSON.stringify(state));
  };
  
  const updateState = ({
	selectIndex,
	selected,
	value = "",
	imageUrl = "",
	text = "",
	customUsed = false,
	parentLabel = "",
  }) => {
	nativeOptionsState[selectIndex].selected = selected;
	nativeOptionsState[selectIndex].value = value;
	nativeOptionsState[selectIndex].imageUrl = imageUrl;
	nativeOptionsState[selectIndex].text = text;
	nativeOptionsState[selectIndex].customUsed = customUsed;
	nativeOptionsState[selectIndex].parentLabel = parentLabel;
  
	updateLocalStorageState(nativeOptionsState);
	updateHiddenInputs();
  };
  
  /* ---------------------------
	 RESET FUNCTION
  --------------------------- */
  const resetSelection = (idx) => {
	const nativeSelect = NATIVE_SELECTS[idx].querySelector("select");
	toggleSelected({ item: nativeSelect, selected: false });
	nativeSelect.value = "";
	NATIVE_SELECTS[idx].dispatchEvent(new Event("change"));
  
	updateCounter({ nativeOptionsState, counter: COUNTER });
	updateInitialButtonWithSelection({
	  buttons: INITIAL_BUTTONS,
	  selectIndex: idx,
	  selectionText: `Forslag ${idx + 1} +`,
	  imageUrl: "",
	});
	updateMainIcons("", idx, "");
	RESET_BUTTONS[idx].classList.remove("!flex");
	INITIAL_BUTTONS[idx].classList.remove("bg-black", "text-white");
  };
  
  RESET_BUTTONS.forEach((resetBtn, idx) => {
	resetBtn.addEventListener("click", (evt) => {
	  evt.stopPropagation();
	  evt.preventDefault();
  
	  updateState({
		selectIndex: idx,
		selected: false,
		value: "",
		imageUrl: "",
		text: "",
		customUsed: false,
		parentLabel: "",
	  });
	  resetSelection(idx);
	});
  });
  
  /* ---------------------------
	 “CLICK A BIG CIRCLE” => SHOW CATEGORIES
  --------------------------- */
  INITIAL_BUTTONS.forEach((btn, idx) => {
	btn.addEventListener("click", () => {
	  selectIndex = idx;
	  clearView(VIEW_2);
	  clearView(VIEW_3);
	  createFormViews();
	});
  });
  
  /* ---------------------------
	 CREATE FORM VIEWS
  --------------------------- */
  const createFormViews = () => {
	createCategoryBack({
	  level: 2,
	  text: "Velg kategori",
	  VIEW_TO_CLEAR: VIEW_2,
	});
  
	SELECT_DATA.forEach((selectCategory) => {
	  const categoryLabel = selectCategory.selectId;
	  const categoryButton = createCategoryButton(categoryLabel);
	  const categoryDiv = createCategoryDiv(categoryLabel);
	  const customInputField = createCustomField(categoryLabel);
	  const overlayTrigger = createOverlayTrigger();
  
	  CUSTOM_INPUT_FIELDS.push(customInputField);
  
	  VIEW_2.appendChild(categoryButton);
	  setActiveView(VIEW_2);
  
	  categoryButton.addEventListener("click", () => {
		setActiveView(VIEW_3);
  
		createCategoryBack({
		  level: 3,
		  text: categoryLabel,
		  VIEW_TO_CLEAR: VIEW_3,
		});
  
		VIEW_3.appendChild(categoryDiv);
		categoryDiv.appendChild(customInputField);
		hideUnselectedCategories(categoryLabel);
		showSelectedCategory(categoryLabel);
		onCustomSelectChange(categoryLabel);
		onCustomInput(categoryLabel);
	  });
  
	  const categoryOptions = selectCategory.children.map((categoryOption) =>
		createCategoryOptions({
		  categoryOption,
		  selectedCustomOption: SELECTED_CUSTOM_OPTIONS,
		  selectIndex,
		  customSelects: CUSTOM_SELECTS,
		})
	  );
	  categoryOptions.forEach((optionBtn) => categoryDiv.appendChild(optionBtn));
  
	  categoryDiv.appendChild(overlayTrigger);
	  overlayTrigger.addEventListener("click", () => {
		customInputField.classList.add("!pointer-events-auto", "!opacity-100", "!scale-100");
	  });
	});
  };
  
  /* ---------------------------
	 SELECT A CHILD OPTION
  --------------------------- */
  const onCustomSelectChange = (parentLabel) => {
	const nativeOptions = [...NATIVE_SELECTS[selectIndex].querySelectorAll("option")];
  
	CUSTOM_SELECTS.forEach(({ optionButton, imageUrl }) => {
	  optionButton.addEventListener("click", (event) => {
		const formattedChoice = formatValue(event.target.textContent.trim());
  
		nativeOptions.forEach((nativeOption) => {
		  const formattedOption = formatValue(nativeOption.textContent.trim());
		  if (formattedOption === formattedChoice) {
			toggleSelected({ item: nativeOption, selected: true });
			NATIVE_SELECTS[selectIndex].dispatchEvent(new Event("change"));
			SELECTED_CUSTOM_OPTIONS.add(formattedChoice);
  
			updateInitialButtonWithSelection({
			  buttons: INITIAL_BUTTONS,
			  selectIndex,
			  selectionText: nativeOption.text,
			  imageUrl,
			});
  
			updateMainIcons(imageUrl, selectIndex, nativeOption.text);
  
			updateState({
			  selectIndex,
			  selected: true,
			  value: formattedOption,
			  text: nativeOption.textContent,
			  imageUrl,
			  customUsed: false,
			  parentLabel: "",
			});
		  }
		});
  
		hideView(VIEW_2);
		hideView(VIEW_3);
		updateCounter({ nativeOptionsState, counter: COUNTER });
	  });
	});
  };
  
  /* ---------------------------
	 CUSTOM INPUT => typed text
	 Also forces <select> to pick the parent's label if you prefer
  --------------------------- */
  const onCustomInput = (parentLabel) => {
	const customFields = [...document.querySelectorAll(".custom-inputs > div")];
  
	CUSTOM_INPUT_FIELDS.forEach((inputField) => {
	  const button = inputField.querySelector("button");
	  const input = inputField.querySelector("input");
	  const textarea = inputField.querySelector("textarea");
  
	  button.addEventListener("click", (event) => {
		event.preventDefault();
  
		const category = button.getAttribute("data-category");
		const customSuggestion = input.value;
		const textAreaValue = textarea.value;
  
		const iconUrlObj = TOP_LEVEL_ICONS.find((icon) => icon.title === category);
		if (!iconUrlObj) {
		  console.warn("No matching parent icon found for:", category);
		  return;
		}
  
		if (customSuggestion) {
		  const imageUrl = iconUrlObj.allIcons[selectIndex] || "";
  
		  const customDivs = [...document.querySelectorAll(".custom-inputs > div")];
		  const customInput = customDivs[selectIndex].querySelector("input");
		  const customTextArea = customDivs[selectIndex].querySelector("textarea");
  
		  customInput.value = customSuggestion;
		  customTextArea.value = textAreaValue;
  
		  // 1) Show typed text on big circle
		  updateInitialButtonWithSelection({
			buttons: INITIAL_BUTTONS,
			selectIndex,
			selectionText: customSuggestion,
			imageUrl,
		  });
  
		  // 2) main <img> alt => typed text
		  updateMainIcons(imageUrl, selectIndex, customSuggestion);
  
		  // 3) Force the <select> to show the parent's label (optional)
		  const nativeSelect = NATIVE_SELECTS[selectIndex].querySelector("select");
		  if (nativeSelect) {
			// Does an <option> exist for 'category'? If not, create it
			let parentOption = [...nativeSelect.options].find((opt) => opt.value === category);
			if (!parentOption) {
			  parentOption = new Option(category, category, true, true);
			  nativeSelect.add(parentOption);
			}
			parentOption.selected = true;
			// Trigger change if needed
			nativeSelect.dispatchEvent(new Event("change"));
		  }
  
		  hideView(VIEW_2);
		  hideView(VIEW_3);
  
		  // 4) Update localStorage + hidden inputs
		  updateState({
			selectIndex,
			selected: true,
			value: customSuggestion,
			text: customSuggestion,
			imageUrl,
			customUsed: true,
			parentLabel: category,
		  });
  
		  updateCounter({ nativeOptionsState, counter: COUNTER });
		}
	  });
	});
  };
  
  /* ---------------------------
	 UPDATE MAIN ICONS
  --------------------------- */

  const updateMainIcons = (imageUrl, idx, altText = "") => {
	// idx === 0 => "left"
	if (idx === 0 && SELECTED_ICON_LEFT.length) {
	  SELECTED_ICON_LEFT.forEach((iconEl) => {
		iconEl.src = imageUrl;
		iconEl.alt = altText;
	  });
	}
	// idx === 1 => "top"
	if (idx === 1 && SELECTED_ICON_TOP.length) {
	  SELECTED_ICON_TOP.forEach((iconEl) => {
		iconEl.src = imageUrl;
		iconEl.alt = altText;
	  });
	}
	// idx === 2 => "bottom"
	if (idx === 2 && SELECTED_ICON_BOTTOM.length) {
	  SELECTED_ICON_BOTTOM.forEach((iconEl) => {
		iconEl.src = imageUrl;
		iconEl.alt = altText;
	  });
	}
  };  