const navigation = document.querySelector('.navigation');
const navigationList = document.querySelector('.navigation-list');
const navButton = document.querySelector('.nav-button');
const body = document.querySelector('body');

navButton.addEventListener("click", () => {
    navigation.classList.toggle('invisible');
    navigation.classList.toggle('opacity-0');
    navigationList.classList.toggle('translate-y-64');

    navButton.classList.toggle('rotate-45');

    body.classList.toggle('fixed');
    body.classList.toggle('md:relative');
});