export const formatValue = (value) => {
	return value ? value.toString().toLowerCase().replace(/å/g, "a") : "";
  };
  
  export const clearView = (view) => {
	view.innerHTML = "";
  };
  
  export const createCategoryButton = (label) => {
	const button = document.createElement("div");
	button.classList.add(
	  "category-option",
	  "flex",
	  "justify-between",
	  "items-center",
	  "cursor-pointer",
	  "text-md",
	  "lg:text-lg",
	  "xl:text-xl",
	  "font-bold",
	  "mb-2",
	  "lg:mb-8"
	);
	button.textContent = label;
	return button;
  };
  
  export const createCategoryDiv = (label) => {
	const div = document.createElement("div");
	div.setAttribute("data-category", label);
	div.classList.add("!flex", "flex-wrap", "gap-4");
	div.style.display = "none";
	return div;
  };
  
  export const hideUnselectedCategories = (categoryLabel) => {
	const allCategories = document.querySelectorAll(
	  `[data-category]:not([data-category="${categoryLabel}"])`
	);
	allCategories.forEach((category) => {
	  category.style.display = "none";
	});
  };
  
  export const showSelectedCategory = (categoryLabel) => {
	const selectedCategory = document.querySelector(`[data-category="${categoryLabel}"]`);
	if (selectedCategory) {
	  selectedCategory.classList.add("data-category");
	}
  };
  
  export const createOptionButton = (label) => {
	const optionButton = document.createElement("div");
	optionButton.classList.add(
	  "select-option",
	  "border-2",
	  "lg:border-[3px]",
	  "border-black",
	  "rounded-full",
	  "pt-[2px]",
	  "pb-4",
	  "px-8",
	  "xl:pt-2",
	  "xl:pb-4",
	  "xl:px-12",
	  "text-xs",
	  "lg:text-base",
	  "font-bold",
	  "cursor-pointer",
	  "hover:bg-black",
	  "hover:text-white",
	  "hover:scale-105",
	  "transition-all",
	  "duration-500",
	  "ease-ease"
	);
	optionButton.textContent = label;
	return optionButton;
  };
  
  export const updateInitialButtonWithSelection = ({
	buttons,
	selectIndex,
	selectionText,
	imageUrl,
  }) => {
	const resetButton = buttons[selectIndex].querySelector(".form-button__reset");
	const buttonLabel = buttons[selectIndex].querySelector(".button-label");
	const selectedIcon = buttons[selectIndex].querySelector(".selected-icon");
	const initialButton = buttons[selectIndex];
  
	if (buttonLabel && selectedIcon) {
	  initialButton.classList.add("text-white", "bg-black");
	  buttonLabel.textContent = selectionText;
	  selectedIcon.src = imageUrl;
	  selectedIcon.alt = selectionText;
	  selectedIcon.classList.add("!block");
	  resetButton.classList.add("!flex");
	}
  };

export const createCustomField = (categoryLabel) => {
	const innerDiv = document.createElement("div");
	const div = document.createElement("div");
	const input = document.createElement("input");
	const textArea = document.createElement("textarea");
	const button = document.createElement("button");
	const closeButton = document.createElement("button");

	innerDiv.textContent = "Skriv inn ditt eget forslag!";
	innerDiv.classList.add("mb-20", "md:mb-40", "font-bold", "text-base", "md:text-md");

	div.appendChild(innerDiv);
	div.setAttribute("data-category", categoryLabel);
	div.classList.add("custom-form__custom-field", "shadow-2xl", "w-[calc(100%-40px)]", "md:w-2/3", "opacity-0", "pointer-events-none", "bg-white", "p-20", "md:p-40", "absolute", "top-1/2", "left-1/2", "-translate-x-1/2", "-translate-y-1/2", "rounded-md", "!flex", "flex-col", 'scale-95', 'transition-all', 'duration-500', 'ease-ease');
	
	input.setAttribute('placeholder', "Navn");
	input.classList.add("outline-none", "mb-8", "text-sm", "md:text-base", "lg:text-md", "bg-sand", "rounded-sm", "md:rounded-base", "pt-8", "pb-12", "px-12", "lg:pt-12", "lg:pb-16", "lg:px-20", "placeholder:text-black/40");
	input.setAttribute('maxLength', 16)
	
	textArea.setAttribute('placeholder', "Beskrivelse");
	textArea.setAttribute('maxLength', 40)
	textArea.setAttribute('rows', 3)
	textArea.classList.add("resize-none", "outline-none", "mb-20", "md:mb-40", "text-sm", "md:text-base", "lg:text-md", "bg-sand", "rounded-sm", "md:rounded-base", "pt-8", "pb-12", "px-12", "lg:pt-12", "lg:pb-16", "lg:px-20", "placeholder:text-black/40");
	
	button.setAttribute("type", "button");
	button.setAttribute("data-category", categoryLabel);
	button.classList.add("button");
	button.textContent = "Legg til forslag";
	button.classList.add("text-sm", "lg:text-base", "xl:text-md", "font-bold", "flex", "items-center", "gap-8", "lg:gap-16", "w-fit", "pt-8", "pb-12", "px-16", "lg:pt-12", "lg:pb-16", "lg:px-20", "rounded-full", "cursor-pointer", "hover:scale-105", "transition-all", "duration-500", "ease-ease", "bg-black", "text-white", "select-none");
	
	closeButton.setAttribute("type", "button");
	closeButton.classList.add("custom-form__custom-close", "absolute", "-right-16", "-top-16", "w-32", "h-32", "md:w-40", "md:h-40", "flex", "justify-center", "items-center", "bg-black", "text-white", "font-bold", "text-sm", "rounded-full");
	closeButton.innerHTML = "✕";

	button.addEventListener("click", (e) => {
		div.classList.remove("!opacity-100", "!pointer-events-auto", "!scale-100");
	});

	closeButton.addEventListener("click", (e) => {
		div.classList.remove("!opacity-100", "!pointer-events-auto", "!scale-100");
	});

	div.appendChild(input);
	div.appendChild(textArea);
	div.appendChild(button);
	div.appendChild(closeButton);

	return div;
};

export const createCategoryBackMarkup = (categoryLabel, callback) => {
	const div = document.createElement("div");
	div.classList.add("category-label", "flex", "gap-12", "md:gap-20", "items-center", "mb-auto");
	const backButton = document.createElement("button");
	backButton.innerHTML =
		'<svg width="100%" height="100%" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="120" y="120" width="120" height="120" rx="60" transform="rotate(-180 120 120)" fill="black"/><path d="M75 37.0002C73.8732 40.0204 72.6338 42.8083 71.2817 45.3639C69.9296 48.0356 68.4085 50.5331 66.7183 52.8563L66.6056 52.8563L66.6056 67.1442L66.7183 67.1442C68.2958 69.4674 69.7606 71.9068 71.1127 74.4624C72.4648 77.1341 73.7042 79.98 74.831 83.0002L61.9859 83.0002C55 74.5204 47.338 68.0735 39 63.6593L39 56.1669C47.338 51.9851 55 45.5962 61.9859 37.0002L75 37.0002Z" fill="#FF6232"/></svg>';
	backButton.classList.add("back-button", "w-32", "h-32", "md:w-40", "md:h-40", "hover:-translate-x-4", 'transition-all', 'duration-500', 'ease-ease');
	backButton.addEventListener("click", (e) => {
		e.preventDefault();
		if (callback) {
			return callback();
		}
	});

	const labelSpan = document.createElement("span");
	labelSpan.classList.add("text-sm", "md:text-base", "lg:text-md", "font-bold");
	labelSpan.textContent = categoryLabel;

	div.appendChild(backButton);
	div.appendChild(labelSpan);

	return div;
};

export const getCorrectImagePosition = (index, childOption) => {
	if (index === 0) return childOption.imageLeft;
	if (index === 1) return childOption.imageTop;
	if (index === 2) return childOption.imageBottom;
	return null;
  };

export const createCategoryBack = ({ level, text = "Velg kategori", VIEW_TO_CLEAR }) => {
	const categoryBackLevel = createCategoryBackMarkup(text, () => {
		VIEW_TO_CLEAR.classList.remove("!opacity-100", "!pointer-events-auto", "!scale-100");
		VIEW_TO_CLEAR.innerHTML = "";
	});

	if (level == 2) {
		VIEW_TO_CLEAR.appendChild(categoryBackLevel);
	}
	if (level === 3) {
		VIEW_TO_CLEAR.appendChild(categoryBackLevel);
	}
};

export const createOverlayTrigger = () => {
	const overlayTrigger = document.createElement("div");
	overlayTrigger.setAttribute("data-overlay-trigger", "");
	overlayTrigger.textContent = "Skriv inn eget forslag";
	overlayTrigger.classList.add(
	  "select-option",
	  "border-2",
	  "lg:border-[3px]",
	  "border-black",
	  "rounded-full",
	  "pt-[2px]",
	  "pb-4",
	  "px-8",
	  "xl:pt-2",
	  "xl:pb-4",
	  "xl:px-12",
	  "text-xs",
	  "lg:text-base",
	  "font-bold",
	  "cursor-pointer",
	  "hover:bg-black",
	  "hover:text-white",
	  "hover:scale-105",
	  "transition-all",
	  "duration-500",
	  "ease-ease"
	);

	return overlayTrigger;
};

export const createCategoryOptions = ({
	categoryOption,
	selectedCustomOption,
	selectIndex,
	customSelects,
  }) => {
	const formattedOptionLabel = formatValue(categoryOption.label);
	const isOptionSelected = selectedCustomOption.has(formattedOptionLabel);
  
	const optionButton = createOptionButton(categoryOption.label);
	if (isOptionSelected) {
	  optionButton.classList.add("option-selected");
	  optionButton.disabled = true;
	}
  
	const imageVariant = getCorrectImagePosition(selectIndex, categoryOption);
  
	// store in customSelects for onCustomSelectChange
	customSelects.push({ optionButton, imageUrl: imageVariant });
  
	return optionButton;
  };

  export const setActiveView = (view) => {
	view.classList.add("!opacity-100", "!pointer-events-auto", "!scale-100");
  };
  export const hideView = (view) => {
	view.classList.remove("!opacity-100", "!pointer-events-auto", "!scale-100");
  };

  export const updateCounter = ({ nativeOptionsState, counter }) => {
	if (!counter) return;
	const numberOfSelected = nativeOptionsState.filter((o) => o.selected).length;
	const nextButton = document.querySelector(".next-button");
  
	counter.textContent = numberOfSelected;
  
	if (nextButton) {
	  if (numberOfSelected === 3) {
		nextButton.classList.add("!bg-orange", "!text-black");
		nextButton.classList.remove("pointer-events-none");
	  } else {
		nextButton.classList.remove("!bg-orange", "!text-black");
		nextButton.classList.add("pointer-events-none");
	  }
	}
  };

  export const toggleSelected = ({ item, selected }) => {
	if (selected) {
	  item.selected = true;
	  return;
	}
	item.selected = false;
  };