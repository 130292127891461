document.addEventListener('DOMContentLoaded', () => {
    const faders = document.querySelectorAll('.in-viewport');

    const options = {
        threshold: 0.1,
        rootMargin: "0px 0px 0px 0px"
    };

    const appearOnScroll = new IntersectionObserver(function (
        entries,
        appearOnScroll
    ) {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.add('been-in-viewport');
                appearOnScroll.unobserve(entry.target);
            }
        });
    }, options);

    faders.forEach(fader => {
        appearOnScroll.observe(fader);
    });
});