// Function to copy the URL to the clipboard
function copyURL(event) {
    const button = event.currentTarget;
    const urlToCopy = button.getAttribute('data-url');
  
    if (!urlToCopy) {
      console.error('Ingen URL fundet i data-url attributten.');
      return;
    }
  
    // Store the original button text
    const originalButtonText = button.textContent;
  
    if (navigator.clipboard && window.isSecureContext) {
      // Use the Clipboard API
      navigator.clipboard.writeText(urlToCopy).then(() => {
        // Change button text to confirmation
        button.textContent = 'Link er kopiert!';
        
        // Revert the button text after 2 seconds
        setTimeout(() => {
          button.textContent = originalButtonText;
        }, 2000);
      }).catch((err) => {
        console.error('Kunne ikke kopiere ved hjælp af Clipboard API:', err);
        fallbackCopyText(urlToCopy, button, originalButtonText);
      });
    } else {
      // Fallback method
      fallbackCopyText(urlToCopy, button, originalButtonText);
    }
  }
  
  // Fallback method for older browsers
  function fallbackCopyText(text, button, originalButtonText) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.position = "fixed";
    textArea.style.top = "-1000px";
    textArea.style.left = "-1000px";
    
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        // Change button text to confirmation
        button.textContent = 'Link er kopiert!';
        
        // Revert the button text after 2 seconds
        setTimeout(() => {
          button.textContent = originalButtonText;
        }, 2000);
      } else {
        console.error('Fallback: Kunne ikke kopiere.');
      }
    } catch (err) {
      console.error('Fallback: Fejl under kopiering:', err);
    }
  
    document.body.removeChild(textArea);
  }
  
  // Attach event listeners to all buttons with the 'copyButton' class
  document.addEventListener('DOMContentLoaded', () => {
    const copyButtons = document.querySelectorAll('.copyButton');
    copyButtons.forEach(button => {
      button.addEventListener('click', copyURL);
    });
  });
  