/**
 * dom.js
 */
export const SELECT_DATA = window.SELECT_DATA;

// Main "pages"/views
export const VIEW_1 = document.querySelector(".custom-form .view-1");
export const VIEW_2 = document.querySelector(".custom-form .view-2");
export const VIEW_3 = document.querySelector(".custom-form .view-3");

// The 3 circle buttons in the first view
export const INITIAL_BUTTONS = VIEW_1 ? [...VIEW_1.querySelectorAll(".form-button")] : [];

// The hidden <select> fields
export const NATIVE_SELECTS = [...document.querySelectorAll("[data-custom-dropdown]")];

// The reset "✕" buttons inside the circle
export const RESET_BUTTONS = [...document.querySelectorAll(".form-button__reset")];

// The "0 of 3" counter
export const COUNTER = document.querySelector(".custom-form__counter span");

// The <img> elements for the left/top/bottom selected icons
export const SELECTED_ICON_LEFT = document.querySelectorAll(".selectedIconLeft");
export const SELECTED_ICON_TOP = document.querySelectorAll(".selectedIconTop");
export const SELECTED_ICON_BOTTOM = document.querySelectorAll(".selectedIconBottom");

// If you want to display the parent's title in these text spans:
export const SUBMITTED_ICON_LEFT = document.querySelector(".submittedIconLeft");
export const SUBMITTED_ICON_TOP = document.querySelector(".submittedIconTop");
export const SUBMITTED_ICON_BOTTOM = document.querySelector(".submittedIconBottom");

// Final labels (if you have a success screen or something)
export const SUBMITTED_LABEL_LEFT = document.querySelectorAll(".submittedLabelLeft");
export const SUBMITTED_LABEL_TOP = document.querySelectorAll(".submittedLabelTop");
export const SUBMITTED_LABEL_BOTTOM = document.querySelectorAll(".submittedLabelBottom");

// Check if we're on a success page
export const FORM_SUCCESS = document.querySelector(".form__success");