document.addEventListener("DOMContentLoaded", () => {
    const parentContainers = document.querySelectorAll('.in-viewport');

    if (parentContainers.length === 0) {
        return;
    }

    function easeOutBack(t) {
        const c1 = 1.70158;
        const c3 = c1 + 1;
        return 1 + c3 * Math.pow(t - 1, 3) + c1 * Math.pow(t - 1, 2);
    }

    function animatePercentage(elem, targetPercentage, progressFill, duration = 5000) {
        if (!elem || isNaN(targetPercentage)) return;

        let startTimestamp = null;

        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = timestamp - startTimestamp;
            const progressRatio = Math.min(progress / duration, 1);
            const easedProgress = easeOutBack(progressRatio);
            const currentPercentage = Math.min(easedProgress * targetPercentage, targetPercentage);

            elem.textContent = `${currentPercentage.toFixed(1)}%`;

            if (progressFill) {
                progressFill.style.height = `${currentPercentage}%`;
            }

            if (progress < duration) {
                window.requestAnimationFrame(step);
            } else {
                elem.textContent = `${targetPercentage}%`;
            }
        };

        window.requestAnimationFrame(step);
    }

    function animateNumber(elem, targetNumber, duration = 5000) {
        if (!elem || isNaN(targetNumber)) return;

        let startTimestamp = null;

        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = timestamp - startTimestamp;
            const progressRatio = Math.min(progress / duration, 1);
            const easedProgress = easeOutBack(progressRatio);
            const currentNumber = Math.min(easedProgress * targetNumber, targetNumber);

            elem.textContent = `${currentNumber.toFixed(0)}`;

            if (progress < duration) {
                window.requestAnimationFrame(step);
            } else {
                elem.textContent = `${targetNumber}`;
            }
        };

        window.requestAnimationFrame(step);
    }

    function startCounterAnimations(container) {
        const percentageElements = container.querySelectorAll(".percentage");
        const numberElements = container.querySelectorAll(".number");
        const progressFills = container.querySelectorAll(".progress-fill");

        percentageElements.forEach((elem, index) => {
            const targetPercentage = parseFloat(elem.getAttribute("data-target"));
            const progressFill = progressFills[index] || null;
            animatePercentage(elem, targetPercentage, progressFill);
        });

        numberElements.forEach((elem) => {
            const targetNumber = parseFloat(elem.getAttribute("data-target"));
            animateNumber(elem, targetNumber);
        });
    }

    // 🔥 Use IntersectionObserver for multiple counters
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                startCounterAnimations(entry.target);
                observer.unobserve(entry.target); // Ensure each counter runs only once
            }
        });
    }, { threshold: 0.5 });

    parentContainers.forEach(container => observer.observe(container));
});
