import "@/styles/index.scss";
import "./modules/Menu";
import "./modules/Header";
import "./modules/Video";
import "./modules/Text";
import "./modules/InViewport";
import "./modules/Tooltip";
import "./modules/Counter";
import "./modules/CopyUrl";
import "./modules/Download";
import "./form/index";

import 'lazysizes';

console.log("%c Developed by ANTI - https://anti.as/", "background: #000; color: #fff;");
