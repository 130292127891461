document.addEventListener("DOMContentLoaded", function () {
    const downloadBtn = document.getElementById("download-button");
    const captureElement = document.getElementById("download-capture");

    if (downloadBtn && captureElement) { // Check if both elements exist
        downloadBtn.addEventListener("click", function () {
            html2canvas(captureElement, {
                scale: 8,
                useCORS: true,
                backgroundColor: null
            }).then(canvas => {
                const padding = 240;
                const ctx = canvas.getContext("2d");

                // Create a new canvas with extra padding
                const newCanvas = document.createElement("canvas");
                newCanvas.width = canvas.width + padding * 2;
                newCanvas.height = canvas.height + padding * 2;

                const newCtx = newCanvas.getContext("2d");
                newCtx.fillStyle = "rgba(0,0,0,0)"; // Transparent background
                newCtx.fillRect(0, 0, newCanvas.width, newCanvas.height);

                // Draw original canvas onto the new one with padding
                newCtx.drawImage(canvas, padding, padding);

                // Download the new canvas as PNG
                const link = document.createElement("a");
                link.href = newCanvas.toDataURL("image/png");
                link.download = "mitt-kulturhavna.png";
                link.click();
            });
        });
    }
});
